import React, { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useReactToPrint } from 'react-to-print'

import { DataContext } from '../init/getData';
import Loader from '../components/loader/loader';
import { Button, Table, Divider } from 'antd';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { dateTableFormatter } from '../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter, statusFormatter } from '../components/formatters/defFormatter';
import { numberFormatter, totalInFormatter, totalOutFormatter } from '../components/formatters/numberFormatter';
import PrintSessionModal from '../components/print/printSessionModal';

const SessionModal = (props) => {

  const { api, userData, session, sessionId, mainCurrency, secondaryCurrency, defaultCurrency } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;


  const { showModal, toggleModal, title, journalVoucher } = props;
  const [isLoading, setIsLoading] = useState(true);

  const [jvs, setJvs] = useState(true);
  const [summary, setSummary] = useState(true);
  const [showPrintComponent, setShowPrintComponent] = useState(false);

  useEffect(() => {
    if (showModal) {
      fetchData();
    }
  }, [showModal]);

  const fetchData = async () => {
    setIsLoading(true)
    const data = {
      sessionId,
    }
    let res = await api.get("/session/view", { params: data });
    if (!res.data.status) {
      toast.error(res.data.message);
      setIsLoading(false)
    } else {
      setIsLoading(false)
      setJvs(res?.data?.groupedData)
      setSummary(res?.data?.summary)
    }
  };

  const isUsingMainCurrency = defaultCurrency.id == mainCurrency.id ? true : false;

  const balance = summary?.totalIn - summary?.totalOut;
  const secondaryBalance = summary?.secondaryTotalIn - summary?.secondaryTotalOut;
  const getBalance = isUsingMainCurrency ? balance : secondaryBalance;

  const openingBalance = isUsingMainCurrency ? session?.openingBalance : session?.secondaryOpeningBalance;


  const totalBalance = (getBalance) + parseFloat(openingBalance);

  const JvColumns = [
    { title: 'Type', dataIndex: 'type' },
    {
      title: 'In',
      dataIndex: isUsingMainCurrency ? 'totalDebit' : 'secondaryTotalDebit',
      render: totalInFormatter,
    },
    {
      title: 'Out ',
      dataIndex: isUsingMainCurrency ? 'totalCredit' : 'secondaryTotalCredit',
      render: totalOutFormatter,
    },
  ]

  const printRef = useRef();
  const handlePrint = useReactToPrint({ content: () => printRef.current });

  const handlePrintStatus = () => {
    if (session && jvs.length > 0 && summary) {
      console.log("Session data before print:", session); // Log the session data being passed to print
      setShowPrintComponent(true);
      setTimeout(() => {
        handlePrint();
      }, 500);
    } else {
      toast.error("Data is still loading, please try again.");
    }
  };




  return (
    <Modal show={showModal} backdrop="static" size={'md'} keyboard={false} onHide={toggleModal}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }} >
      <Modal.Header style={{ display: 'flex', justifyContent: 'center', background: "#23576a" }}>
        <Modal.Title style={{ color: "white" }} >View Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {isLoading &&
          <Loader />
        }

        {!isLoading &&
          <>
            <Row>
              <Col md={4}>
                <span style={{ fontWeight: 'bold' }}>
                  OpenedAt:
                </span>
              </Col>
              <Col md={8}>
                {dateTableFormatter(session?.openedAt)}
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={4}>
                <span style={{ fontWeight: 'bold' }}>
                  Opening Balance:
                </span>
              </Col>
              <Col md={8}>
                {numberFormatter(openingBalance)}{defaultCurrency?.symbol}
              </Col>
            </Row>

            <Row className="mb-4">
              <Table columns={JvColumns} dataSource={jvs} pagination={false} />
            </Row>

            <Row className="mb-4">
              <Col md={6}>
              </Col>
              <Col md={3}>
                <span style={{ fontWeight: 'bold' }}>
                  Balance:
                </span>
              </Col>
              <Col md={2}>
                <span style={{ fontWeight: 'bold', color: '#008000' }}>
                  {numberFormatter(totalBalance)}{defaultCurrency?.symbol}
                </span>
              </Col>
            </Row>
          </>
        }


      </Modal.Body>

      <Modal.Footer>
        <Button type="primary" size="large" onClick={toggleModal} danger>Close</Button>
        <Button type="default" size="large" onClick={handlePrintStatus}>Print</Button>
      </Modal.Footer>

      {showPrintComponent && (
        <div style={{ display: 'none' }}>
          {/* Pass session from state to ComponentViewSession for printing */}
          <PrintSessionModal
          ref={printRef}
          session={session}
          jvs={jvs}
          summary={summary} 
          isUsingMainCurrency={isUsingMainCurrency}
          defaultCurrency={defaultCurrency}
        />
        </div>
      )}

    </Modal>
  )
}


export default SessionModal;