import React, { useState, useContext, useMemo, useEffect, useCallback, Suspense } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/loader/loader';

import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Avatar, Breadcrumb, Dropdown } from 'antd';
import { FiGrid, FiArchive, FiUsers, FiTool, FiShoppingCart, FiPackage, FiShoppingBag, FiLogOut, FiMenu, } from "react-icons/fi";
import { DatePicker } from 'antd';
import {
  FcSupport, FcContacts, FcPackage, FcSettings, FcSurvey, FcBusinessman, FcMoneyTransfer, FcCurrencyExchange, FcKindle,
  FcComboChart, FcDocument, FcMultipleDevices, FcDiploma1, FcImport, FcDisclaimer
} from "react-icons/fc";

import { DataContext } from '../init/getData';
import SessionModal from './sessionModal';
import EndSessionModal from './endSessionModal';

// Lazy-loaded components
const SalesDashboardLazy = React.lazy(() => import('../dashboardTab/salesDashboard/salesDashboard.js'));


//Daily Entry
const DailyEntryLazy = React.lazy(() => import('../dailyEntry/dailyEntry'));


//Accounts
const ChartTabLazy = React.lazy(() => import('../accountTab/chart/chartTabs'));
const AuxAccountLazy = React.lazy(() => import('../accountTab/auxAccount/auxAccount'));
const GroupAccountLazy = React.lazy(() => import('../accountTab/group/group'));
const DeliveryLazy = React.lazy(() => import('../accountTab/delivery/delivery'));

//Cash
const ReceiptsLazy = React.lazy(() => import('../cashControlTab/receipts/receipts'));
const PaymentLazy = React.lazy(() => import('../cashControlTab/payments/payments'));
const CashAccountLazy = React.lazy(() => import('../cashControlTab/cashAccount/cashAccount'));
const VarianceAccountLazy = React.lazy(() => import('../cashControlTab/varianceAccount/varianceAccount'));
const CashTransferLazy = React.lazy(() => import('../cashControlTab/cashTransfer/cashTransfer'));

// HR Payroll
const EmployeeLazy = React.lazy(() => import('../hrTab/employee/employee'));
const SalaryLazy = React.lazy(() => import('../hrTab/salary/salary'));

//Purchase
const PurchaseInvoiceLazy = React.lazy(() => import('../purchaseTab/purchaseInvoice/purchaseInvoice'));
const SupplierLazy = React.lazy(() => import('../purchaseTab/supplier/supplier'));

//Sale
const CustomerLazy = React.lazy(() => import('../salesTab/customer/customer'));
const SaleInvoiceLazy = React.lazy(() => import('../salesTab/salesInvoice/salesInvoice'));

//Expenses
const ExpenseAccountLazy = React.lazy(() => import('../expensesTab/expenseAccount/expenseAccount'));
const ExpenseSupplierLazy = React.lazy(() => import('../expensesTab/expenseSupplier/expenseSupplier'));
const ExpenseInvoiceLazy = React.lazy(() => import('../expensesTab/expenseInvoice/expenseInvoice'));

//Inventory
const ProductLazy = React.lazy(() => import('../inventoryTab/product/product'));
const CategoryLazy = React.lazy(() => import('../inventoryTab/category/category'));
const BrandLazy = React.lazy(() => import('../inventoryTab/brand/brand'));
const SeasonLazy = React.lazy(() => import('../inventoryTab/season/season'));
const WarehouseLazy = React.lazy(() => import('../devTab/warehouse/warehouse'));
const VatGroupLazy = React.lazy(() => import('../inventoryTab/vatGroup/vatGroup'));


//Report
const FinancialReportsLazy = React.lazy(() => import('../reportTab/financialReports/financialReports'));
const OperationalReportsLazy = React.lazy(() => import('../reportTab/operationalReports/operationalReports'));
const SessionLazy = React.lazy(() => import('../reportTab/session/session'));
const StockReportsLazy = React.lazy(() => import('../reportTab/stockReports/stockReports'));


//Stock
const StockControlLazy = React.lazy(() => import('../stockTab/stockControl/stockControl'));
const StockTransferLazy = React.lazy(() => import('../stockTab/stockTransfer/stockTransfer'));

//Tools
const StockToolsLazy = React.lazy(() => import('../toolsTab/stockTools/stockTools'));
const OpeningYearLazy = React.lazy(() => import('../toolsTab/openingYear/openingYear'));
const PosToolsLazy = React.lazy(() => import('../toolsTab/pos/pos'));
const ImportToolsLazy = React.lazy(() => import('../toolsTab/import/importTools'));

//Dev Tab
const BranchLazy = React.lazy(() => import('../devTab/branch/branch'));
const CompanyLazy = React.lazy(() => import('../devTab/company/company'));
const YearLazy = React.lazy(() => import('../devTab/year/year'));
const AppModeLazy = React.lazy(() => import('../devTab/appMode/appMode'));

//Settings
const GeneralLazy = React.lazy(() => import('../settingTab/general/general'));
const LinkAccountLazy = React.lazy(() => import('../settingTab/linkAccount/linkAccount'));
const UserLazy = React.lazy(() => import('../settingTab/user/user'));
const RoleLazy = React.lazy(() => import('../settingTab/role/role'));
const CurrencyLazy = React.lazy(() => import('../settingTab/currency/currency'));

//retail
const PosLazy = React.lazy(() => import('../retailTab/pos/pos'));


const { Header, Sider, Content } = Layout;






const LeftMenu = (props) => {

  const { userData, setAccess, selectedYear, selectedCompany } = useContext(DataContext);

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);
  const navigate = useNavigate();

  const userRole = userData?.role;
  const userId = userData?.id;


  const items = useMemo(() => [
    userRole.dashboardTab && {
      key: 'Dashboard',
      label: 'Dashboard',
      icon: <FcComboChart size={22} />,
      children: [
        {
          key: 'Sales Dashboard',
          label: 'Sales',
        },
      ]

    },
    userRole.dailyEntryTab && {
      key: 'DailyEntry',
      label: 'DailyEntry',
      icon: <FcDiploma1 size={22} />,
    },
    userRole.accountTab && {
      key: 'Accounts',
      icon: <FcContacts size={22} />,
      label: 'Accounts',
      children: [
        {
          key: 'Chart Of Accounts',
          label: 'Chart Of Accounts',
        },
        {
          key: 'My Accounts',
          label: 'My Accounts',
        },
        {
          key: 'Groups',
          label: 'Groups',
        },
        {
          key: 'Delivery',
          label: 'Delivery',
        },
      ]
    },
    userRole.cashTab && {
      key: 'Cash Control',
      icon: <FcMoneyTransfer size={21} />,
      label: 'Cash Control',
      children: [
        {
          key: 'Cash Accounts',
          label: 'Cash Accounts',
        },
        {
          key: 'Variance Accounts',
          label: 'Variance Accounts',
        },
        {
          key: 'Cash Transfer',
          label: 'Cash Transfer',
        },
        {
          key: 'Payments',
          label: 'Payments',
        },
        {
          key: 'Receipts',
          label: 'Receipts',
        },
      ]
    },
    userRole.hrTab && {
      key: 'HR Payroll',
      icon: <FcBusinessman size={22} />,
      label: 'HR Payroll',
      children: [
        {
          key: 'Employee',
          label: 'Employee',
        },
        {
          key: 'Salary',
          label: 'Salary',
        },
      ]
    },
    userRole.purchaseTab && {
      key: 'Purchases',
      icon: <FcDocument size={22} />,
      label: 'Purchases',
      children: [
        {
          key: 'Supplier',
          label: 'Supplier',
        },
        {
          key: 'Purchase Invoice',
          label: 'Purchase Invoice',
        },
        {
          key: 'Purchase Return',
          label: 'Purchase Return',
        },
        {
          key: 'Purchase Order',
          label: 'Purchase Order',
        },
      ]
    },
    userRole.salesTab && {
      key: 'Sales',
      icon: <FiShoppingCart strokeWidth={2.5} color={'red'} size={20} />,
      label: 'Sales',
      children: [
        {
          key: 'Customer',
          label: 'Customer',
        },
        {
          key: 'Sales Invoice',
          label: 'Sales Invoice',
        },
        {
          key: 'Sales Return',
          label: 'Sales Return',
        },
        {
          key: 'Sales Order',
          label: 'Sales Order',
        },
      ]
    },
    userRole.expenseTab && {
      key: 'Expenses',
      icon: <FcKindle size={20} />,
      label: 'Expenses',
      children: [
        {
          key: 'Expense Account',
          label: 'Expense Account',
        },
        {
          key: 'Expense Supplier',
          label: 'Expense Supplier',
        },
        {
          key: 'Expense Invoice',
          label: 'Expense Invoice',
        },
      ]
    },
    userRole.stockTab && {
      key: 'Stock',
      icon: <FcPackage size={22} />,
      label: 'Stock',
      children: [
        {
          key: 'Stock Control',
          label: 'Stock Control',
        },
        {
          key: 'Stock Transfer',
          label: 'Stock Transfer',
        },
      ]
    },
    userRole.inventoryTab && {
      key: 'Inventory',
      icon: <FiPackage strokeWidth={2.5} color={'green'} size={20} />,
      label: 'Inventory',
      children: [
        {
          key: 'Products',
          label: 'Products',
        },
        {
          key: 'Categories',
          label: 'Categories',
        },
        {
          key: 'Brands',
          label: 'Brands',
        },
        {
          key: 'Seasons',
          label: 'Seasons',
        },
        {
          key: 'VatGroup',
          label: 'VatGroup',
        },
      ]
    },
    userRole.reportTab && {
      key: 'Reports',
      label: 'Reports',
      icon: <FcSurvey size={22} />,
      children: [
        {
          key: 'Financial',
          label: 'Financial',
        },
        {
          key: 'Operational',
          label: 'Operational',
        },
        {
          key: 'Stock Reports',
          label: 'Stock',
        },
        {
          key: 'Sessions',
          label: 'Sessions',
        }
      ]

    },

    userRole.toolTab && {
      key: 'Tools',
      label: 'Tools',
      icon: <FcSupport size={22} />,
      children: [
        { key: 'Stock Tools', label: 'Stock Tools' },
        {
          key: 'Opening Year',
          label: 'Opening Year',
        },
        {
          key: 'Pos',
          label: 'Pos',
        },
        {
          key: 'Import',
          label: 'Import',
        },
        {
          key: 'BackUp',
          label: 'BackUp',
        },
      ]

    },
    userRole.settingTab && {
      key: 'Settings',
      label: 'Settings',
      icon: <FcSettings size={22} />,
      children: [
        { key: 'General', label: 'General' },
        { key: 'User', label: 'User' },
        { key: 'Role', label: 'Role' },
        { key: 'Link Account', label: 'Link Account' },
        { key: 'Currency', label: 'Currency' },
      ]
    },
    userId === 777 && {
      key: 'DevSettings',
      label: 'DevSettings',
      icon: <FcSettings size={22} />,
      children: [
        { key: 'Year', label: 'Year' },
        { key: 'Company', label: 'Company' },
        { key: 'Branch', label: 'Branch' },
        { key: 'Warehouse', label: 'Warehouse' },
        { key: 'AppMode', label: 'AppMode' },
      ],
    },
    {
      type: 'divider',
    },
    userRole.retailTab && {
      key: 'Retail',
      label: 'Retail',
      icon: <FcMultipleDevices size={22} />,
      children: [
        {
          key: 'POS Sales',
          label: 'POS',
        },
      ]

    },
  ], []);

  const userMenu = useMemo(() => [
    {
      label: 'View Profile',
      key: '0',
      icon: <FcBusinessman size={20} />,
    },
    userId !== 777 && {
      label: 'View Session',
      key: '1',
      icon: <FcDocument size={19} />,
      onClick: () => setShowSessionModal(!showSessionModal),
    },
    userId !== 777 && {
      label: 'End Session',
      key: '2',
      icon: <FcDisclaimer size={20} />,
      onClick: () => setShowEndSessionModal(!showEndSessionModal),
    },
    {
      type: 'divider',
    },
    {
      label: 'LogOut',
      key: '3',
      icon: <FcImport size={20} />,
      onClick: () => logOut(),
    },
  ], []);

  const logOut = () => {
    setAccess(false);
  }


  const handleMenuClick = useCallback(({ key, keyPath }) => {
    navigate(`/${key}`);
    const title = keyPath.at(-1);
    const newBreadcrumbItems = keyPath.length === 1 ? [{ title: 'Home' }, { title }] : [{ title }, { title: key }];
    if (JSON.stringify(newBreadcrumbItems) !== JSON.stringify(breadcrumbItems)) {
      setBreadcrumbItems(newBreadcrumbItems);
    }
  }, [navigate, breadcrumbItems]);


  const onOpenChange = useCallback((keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  }, [openKeys]);






  return (
    <Layout style={{ userSelect: 'none' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={items}
          onClick={handleMenuClick}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          style={{ height: '100vh', overflowY: 'auto', userSelect: 'none' }}
          className="custom-scrollbar"
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: '#fefefe', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{ fontSize: '16px', width: 64, height: 64 }}
            />
            <Breadcrumb
              style={{ margin: '0 16px' }}
              items={breadcrumbItems}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '1%', justifyContent: 'space-between', gap: '20px', }}>
            <span>{selectedCompany?.name}.{selectedYear?.label}</span>
            <Dropdown menu={{ items: userMenu }} trigger={['click']} >
              <div
                style={{
                  marginRight: '2%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  cursor: 'pointer',
                }}
              >
                <Avatar size="small" icon={<UserOutlined />} />
                <span>{userData.name}</span>
                <CaretDownOutlined />
              </div>
            </Dropdown>
          </div>
        </Header>

        <Content
          style={{
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            background: '#f9fafa',
            borderRadius: 8,
            overflowY: 'auto',
            maxHeight: '90vh'
          }}
        >
          <Routes>

            {/* Dashboard */}
            <Route path="Sales Dashboard" element={<React.Suspense fallback={<Loader />}><SalesDashboardLazy /></React.Suspense>} key="SalesDashboardLazy" />

            {/* DailyEntry */}
            <Route path="DailyEntry" element={<React.Suspense fallback={<Loader />}><DailyEntryLazy /></React.Suspense>} />


            {/* Accounts */}
            <Route path="Chart Of Accounts" element={<React.Suspense fallback={<Loader />}><ChartTabLazy /></React.Suspense>} />
            <Route path="My Accounts" element={<React.Suspense fallback={<Loader />}><AuxAccountLazy /></React.Suspense>} />
            <Route path="Groups" element={<React.Suspense fallback={<Loader />}><GroupAccountLazy /></React.Suspense>} />
            <Route path="Delivery" element={<React.Suspense fallback={<Loader />}><DeliveryLazy /></React.Suspense>} />

            {/* Cash */}
            <Route path="Receipts" element={<React.Suspense fallback={<Loader />}><ReceiptsLazy type={'Receipts'} isDebit={false} isOrder={true} /></React.Suspense>} />
            <Route path="Payments" element={<React.Suspense fallback={<Loader />}><PaymentLazy type={'Payment'} isDebit={true} isOrder={false} /></React.Suspense>} />
            <Route path="Cash Accounts" element={<React.Suspense fallback={<Loader />}><CashAccountLazy /></React.Suspense>} />
            <Route path="Variance Accounts" element={<React.Suspense fallback={<Loader />}><VarianceAccountLazy /></React.Suspense>} />
            <Route path="Cash Transfer" element={<React.Suspense fallback={<Loader />}><CashTransferLazy /></React.Suspense>} />


            {/* HR Payroll */}
            <Route path="Employee" element={<React.Suspense fallback={<Loader />}><EmployeeLazy /></React.Suspense>} />
            <Route path="Salary" element={<React.Suspense fallback={<Loader />}><SalaryLazy type={'Salary'} /></React.Suspense>} />

            {/* Purchase */}
            <Route path="Supplier" element={<React.Suspense fallback={<Loader />}><SupplierLazy /></React.Suspense>} />
            <Route path="Purchase Invoice" element={<React.Suspense fallback={<Loader />}><PurchaseInvoiceLazy type={'PurchaseInvoice'} isDebit={true} isOrder={false} /></React.Suspense>} />
            <Route path="Purchase Return" element={<React.Suspense fallback={<Loader />}><PurchaseInvoiceLazy type={'PurchaseReturn'} isDebit={false} isOrder={false} /></React.Suspense>} />
            <Route path="Purchase Order" element={<React.Suspense fallback={<Loader />}><PurchaseInvoiceLazy type={'PurchaseOrder'} isDebit={true} isOrder={true} /></React.Suspense>} />


            {/* Sales */}
            <Route path="Customer" element={<React.Suspense fallback={<Loader />}><CustomerLazy /></React.Suspense>} />
            <Route path="Sales Invoice" element={<React.Suspense fallback={<Loader />}><SaleInvoiceLazy type={'SalesInvoice'} isDebit={false} isOrder={false} /></React.Suspense>} />
            <Route path="Sales Return" element={<React.Suspense fallback={<Loader />}><SaleInvoiceLazy type={'SalesReturn'} isDebit={true} isOrder={false} /></React.Suspense>} />
            <Route path="Sales Order" element={<React.Suspense fallback={<Loader />}><SaleInvoiceLazy type={'SalesOrder'} isDebit={false} isOrder={true} /></React.Suspense>} />

            {/* Expenses */}
            <Route path="Expense Account" element={<React.Suspense fallback={<Loader />}><ExpenseAccountLazy /></React.Suspense>} />
            <Route path="Expense Supplier" element={<React.Suspense fallback={<Loader />}><ExpenseSupplierLazy /></React.Suspense>} />
            <Route path="Expense Invoice" element={<React.Suspense fallback={<Loader />}><ExpenseInvoiceLazy type={'ExpenseInvoice'} isDebit={true} isOrder={false} Payment /></React.Suspense>} />

            {/* Inventory */}
            <Route path="Products" element={<React.Suspense fallback={<Loader />}><ProductLazy /></React.Suspense>} />
            <Route path="Categories" element={<React.Suspense fallback={<Loader />}><CategoryLazy /></React.Suspense>} />
            <Route path="Brands" element={<React.Suspense fallback={<Loader />}><BrandLazy /></React.Suspense>} />
            <Route path="Seasons" element={<React.Suspense fallback={<Loader />}><SeasonLazy /></React.Suspense>} />
            <Route path="Warehouse" element={<React.Suspense fallback={<Loader />}><WarehouseLazy /></React.Suspense>} />
            <Route path="vatGroup" element={<React.Suspense fallback={<Loader />}><VatGroupLazy /></React.Suspense>} />


            {/* Reports */}
            <Route path="Financial" element={<React.Suspense fallback={<Loader />}><FinancialReportsLazy /></React.Suspense>} />
            <Route path="Operational" element={<React.Suspense fallback={<Loader />}><OperationalReportsLazy /></React.Suspense>} />
            <Route path="Sessions" element={<React.Suspense fallback={<Loader />}><SessionLazy /></React.Suspense>} />
            <Route path="Stock Reports" element={<React.Suspense fallback={<Loader />}><StockReportsLazy /></React.Suspense>} />


            {/* Stock */}
            <Route path="Stock Control" element={<React.Suspense fallback={<Loader />}><StockControlLazy /></React.Suspense>} />
            <Route path="Stock Transfer" element={<React.Suspense fallback={<Loader />}><StockTransferLazy /></React.Suspense>} />

            {/* Tools */}
            <Route path="Stock Tools" element={<React.Suspense fallback={<Loader />}><StockToolsLazy /></React.Suspense>} />
            <Route path="Opening Year" element={<React.Suspense fallback={<Loader />}><OpeningYearLazy /></React.Suspense>} />
            <Route path="Pos" element={<React.Suspense fallback={<Loader />}><PosToolsLazy /></React.Suspense>} />
            <Route path="Import" element={<React.Suspense fallback={<Loader />}><ImportToolsLazy /></React.Suspense>} />


            {/* Setting */}

            <Route path="General" element={<React.Suspense fallback={<Loader />}><GeneralLazy /></React.Suspense>} />
            <Route path="Link Account" element={<React.Suspense fallback={<Loader />}><LinkAccountLazy /></React.Suspense>} />
            <Route path="User" element={<React.Suspense fallback={<Loader />}><UserLazy /></React.Suspense>} />
            <Route path="Role" element={<React.Suspense fallback={<Loader />}><RoleLazy /></React.Suspense>} />
            <Route path="Currency" element={<React.Suspense fallback={<Loader />}><CurrencyLazy /></React.Suspense>} />

            {/* Dev */}
            <Route path="Year" element={<React.Suspense fallback={<Loader />}><YearLazy /></React.Suspense>} />
            <Route path="Branch" element={<React.Suspense fallback={<Loader />}><BranchLazy /></React.Suspense>} />
            <Route path="Company" element={<React.Suspense fallback={<Loader />}><CompanyLazy /></React.Suspense>} />
            <Route path="AppMode" element={<React.Suspense fallback={<Loader />}><AppModeLazy /></React.Suspense>} />


            {/* Retail */}
            <Route path="POS Sales" element={<React.Suspense fallback={<Loader />}><PosLazy /></React.Suspense>} />

          </Routes>

        </Content>
      </Layout>


      {userData !== 777 &&
        <>
          <SessionModal
            showModal={showSessionModal}
            toggleModal={() => setShowSessionModal(!showSessionModal)}
          />

          <EndSessionModal
            showModal={showEndSessionModal}
            toggleModal={() => setShowEndSessionModal(!showEndSessionModal)}
            setAccess={setAccess}
          />
        </>
      }

    </Layout>
  );
};
export default React.memo(LeftMenu);