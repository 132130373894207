import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FcBusinessman, FcKey } from "react-icons/fc";
import GetData from '../init/getData';
import { Input, Radio, Space, Spin } from 'antd';
import { Col, Form, Row, DropdownButton, Dropdown, InputGroup } from 'react-bootstrap';
import Loader from '../components/loader/loader';
import Select from 'react-select';
import Session from './session';


function SelectCompany(props) {

  const { api, devSetting, setAccess, expireyDate, userData, localApi, serverApi } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [yearData, setYearData] = useState([]);

  const [submit, setSubmit] = useState(false);

  // check if company and year are roled
  const userRole = userData.role;
  const userCompany = companyData.find(company => company.id == userRole.companyId);
  const userYear = userCompany?.years?.find(year => year.id == userRole.yearId);

  useEffect(() => {
    if (userCompany && userYear) {
      setSubmit(true);
      setSelectedCompany(userCompany);
      setSelectedYear(userYear);
    }
  }, [userCompany, userYear]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedCompany) {
      toast.error('please select company')
      return
    }
    if (!selectedYear) {
      toast.error('please select year')
      return
    }
    setSubmit(true)
  }


  const handleCompanySelection = (value) => {
    setSelectedCompany(value);
    setSelectedYear("")
    setYearData(value?.years)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getcompany();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);

  // company Options
  const companyOptions = companyData?.map(item => ({
    value: item.id,
    label: item.name,
  }))

  // Year Options
  const yearOptions = yearData?.map(item => ({
    value: item.id,
    label: item.year,
    year: item.year,
    id: item.id,
  }))
  


  const getcompany = async () => {
    let data = { filter: { isActive: 'true' } };
    let res = await api.get("/company/getAll", { params: data });
    if (!res.data.status) {
      toast.error(res.data.message);
      return false;
    } else {
      setCompanyData(res.data.data);
      return true;
    }
  };

  if (submit) {
    return (
      <Session
        api={api}
        selectedCompany={selectedCompany}
        selectedYear={selectedYear}
        setAccess={setAccess}
        devSetting={devSetting}
        expireyDate={expireyDate}
        userData={userData}
        localApi={localApi}
        serverApi={serverApi}
      />
    )
  }
  else {
    return (

      <div style={{ height: '100vh', backgroundColor: '#23576a' }}>
        <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh', backgroundColor: '#23576a' }}>
          <div className="row justify-content-center form-bg-image" style={{ width: 'auto' }}>
            <div className="col-12 d-flex align-items-center justify-content-center" >

              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500" style={{ backgroundColor: '#23576a' }}>
                <div className="text-center text-md-center mb-4 mt-md-0" >
                  <h1 className="mb-0 h3">Please Select Company</h1>
                </div>

                <form>


                  {isLoading &&
                    <div style={{ height: '350px', textAlign: 'center', paddingTop: '40%' }}>
                      <Spin tip="Loading" size="large"> </Spin>
                    </div>
                  }

                  {!isLoading &&
                    <>
                      <div style={{ height: '300px', overflowY: 'auto' }}>
                        <Radio.Group onChange={(e) => handleCompanySelection(e.target.value)} value={selectedCompany}>
                          <Space direction="vertical">
                            {companyData?.map((company, index) => (
                              <Radio key={index} value={company}>
                                {company.name}
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>

                      </div>


                      <Select
                        options={yearOptions}
                        isSearchable={true}
                        placeholder="Select Year"
                        onChange={setSelectedYear}
                        value={selectedYear}

                      />


                      <div className="d-grid" style={{ textAlign: 'center', marginTop: '10px' }}>
                        <button style={{ backgroundColor: '#23576a', fontWeight: 'bold' }}
                          className="btn btn-primary"
                          onClick={handleSubmit}>
                          Continue
                        </button>
                      </div>
                    </>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <p style={{ color: 'white', fontWeight: 'bold' }}>&nbsp; &nbsp;  Copyright @  | By Riser Team</p>
          <p style={{ color: 'white', fontWeight: 'bold' }}>&nbsp; &nbsp;  Whatsapp: +961 78937719 &nbsp; &nbsp;   Email:&nbsp;risersoftware@gmail.com</p>
        </div>
      </div>

    )
  }
}



export default SelectCompany;