import React, { useState, useEffect, createContext, useMemo, useCallback } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import PosCartData from './posCartData';
import Loader from '../components/loader/loader';

import { Flex, Spin } from 'antd';
import { link } from '../dynamic/api';

export const DataContext = createContext();
export const CartContext = createContext();

function GetData(props) {

  const { setAccess, selectedCompany, selectedYear, expireyDate, api, devSetting, userData, session, localApi, serverApi } = props;

  const companyId = selectedCompany.id;
  const yearId = selectedYear.id;
  const sessionId = session?.id;
  const userRole = userData?.role;

  const [isLoading, setIsLoading] = useState(true);
  const [tablesData, setTablesData] = useState([]);
  const [printerData, setPrinterData] = useState([]);

  //Account Tab
  const [chartData, setChartData] = useState([]);
  const [parentAccountData, setParentAccountData] = useState([]);
  const [auxAccountData, setAuxAccountData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [accountGroupData, setAccountGroupData] = useState([]);
  const [customerAccountData, setCustomerAccountData] = useState([]);
  const [supplierAccountData, setSupplierAccountData] = useState([]);
  const [employeeAccountData, setEmployeeAccountData] = useState([]);
  const [expenseSupplierAccountData, setExpenseSupplierAccountData] = useState([]);
  const [expenseAccountData, setExpenseAccountData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const [devData, setDevData] = useState(devSetting);

  // Jv Data
  const [invoiceData, setInvoiceData] = useState([]);
  const [purchaseInvoiceData, setPurchaseInvoiceData] = useState([]);

  // setting
  const [projectData, setProjectData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [vatGroupData, setVatGroupData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);


  // Tools
  const [posDetailsData, setPosDetailsData] = useState([]);

  // inventory

  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [seasonData, setSeasonData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [salableData, setSalableData] = useState([]);

  // Tempor run
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataArray = ['setting', 'currency', 'auxAccount']
        await refData(dataArray, companyId);
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    if (isLoading) {
      fetchData();
    }
  }, []);


  const mainCurrency = settingData?.mainCurrency;
  const secondaryCurrency = settingData?.secondaryCurrency;

  const defaultCurrency = userRole?.defaultCurrency;

  // used only in jv
  const rateMainToSecondaryCurrency = (number) => {
    if (secondaryCurrency?.isMultiply) {
      return number / secondaryCurrency?.rate;
    } else {
      return number * secondaryCurrency?.rate;
    }
  }

  // used  in loading jv 
  const rateToMainCurrency = (selectedCurrency, number) => {
    if (selectedCurrency?.isMultiply) {
      return number * selectedCurrency?.rate;
    } else {
      return number / selectedCurrency?.rate;
    }
  }

  // used  in invoice 
  const rateFromToCurrency = (fromCurrency, toCurrency, number) => {
    let numberToMain;

    if (fromCurrency?.isMultiply) {
      numberToMain = number * fromCurrency?.rate;
    } else {
      numberToMain = number / fromCurrency?.rate;
    }


    let numberToCurrency;

    if (toCurrency?.isMultiply) {
      numberToCurrency = numberToMain / toCurrency?.rate;
    } else {
      numberToCurrency = numberToMain * toCurrency?.rate;
    }

    return numberToCurrency;
  }

  // Get Jv Data
  const refData = useCallback(async (dataArray, companyId, yearId) => {
    try {
      const data = {
        dataArray,
        companyId,
        yearId,
      }
      const response = await api.get(`/getData/refData`, { params: data });
      if (response) {
        const data = response?.data?.data;

        // Jv
        if (dataArray.includes('purchaseInvoice')) { setPurchaseInvoiceData(data.purchaseInvoiceData?.data) };

        // accounts
        if (dataArray.includes('account')) { setAccountData(data.accountData?.data) };
        if (dataArray.includes('chart')) { setChartData(data.chartData?.data) };
        if (dataArray.includes('parentAccount')) { setParentAccountData(data.parentAccountData?.data) };
        if (dataArray.includes('auxAccount')) { setAuxAccountData(data.auxAccountData?.data) };
        if (dataArray.includes('accountGroup')) { setAccountGroupData(data.accountGroupData?.data) };
        if (dataArray.includes('customerAccount')) { setCustomerAccountData(data.customerAccountData?.data) };
        if (dataArray.includes('supplierAccount')) { setSupplierAccountData(data.supplierAccountData?.data) };
        if (dataArray.includes('employeeAccount')) { setEmployeeAccountData(data.employeeAccountData?.data) };
        if (dataArray.includes('expenseSupplierAccount')) { setExpenseSupplierAccountData(data.expenseSupplierAccountData?.data) };
        if (dataArray.includes('expenseAccount')) { setExpenseAccountData(data.expenseAccountData?.data) };
        if (dataArray.includes('delivery')) { setDeliveryData(data.deliveryData?.data) };
        if (dataArray.includes('chartTree')) { setTreeData(data.chartTreeData?.data) };

        // dev
        if (dataArray.includes('devData')) { setDevData(data.devData?.data) };

        // tools
        if (dataArray.includes('posDetails')) { setPosDetailsData(data.posDetailsData?.data) };

        // setting
        if (dataArray.includes('branch')) { setBranchData(data.branchData?.data) };
        if (dataArray.includes('department')) { setDepartmentData(data.departmentData?.data) };
        if (dataArray.includes('vatGroup')) { setVatGroupData(data.vatGroupData?.data) };
        if (dataArray.includes('project')) { setProjectData(data.projectData?.data) };
        if (dataArray.includes('setting')) { setSettingData(data.settingData?.data) };
        if (dataArray.includes('company')) { setCompanyData(data.companyData?.data) };
        if (dataArray.includes('currency')) { setCurrencyData(data.currencyData?.data) };
        if (dataArray.includes('role')) { setRoleData(data.roleData?.data) };
        if (dataArray.includes('year')) { setYearData(data.yearData?.data) };
        if (dataArray.includes('user')) { setAllUserData(data.userData?.data) };


        //inventory
        if (dataArray.includes('product')) { setProductData(data.productData?.data) };
        if (dataArray.includes('salable')) { setSalableData(data.salableData?.data) };
        if (dataArray.includes('brand')) { setBrandData(data.brandData?.data) };
        if (dataArray.includes('category')) { setCategoryData(data.categoryData?.data) };
        if (dataArray.includes('season')) { setSeasonData(data.seasonData?.data) };
        if (dataArray.includes('warehouse')) { setWarehouseData(data.warehouseData?.data) };
      }
    } catch (error) {
      toast.error('' + error);
    }
  }, []);


  // Get Invoice
  const getInvoiceData = async (accountId, type) => {

    try {
      const data = {
        accountId,
        type,
      }
      const response = await api.get(`/journalVoucher/getInvoice`, { params: data });
      if (response) {
        const data = response?.data?.data;
        setInvoiceData(data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  const getOptions = (data) => {
    const options = data?.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return options;
  }

  const currencyOptions = useMemo(() => currencyData?.map(item => ({
    value: item.id,
    label: item.code,
    symbol: item.symbol,
    rate: item.rate,
  })), [currencyData]);

  const vatGroupOptions = useMemo(() => vatGroupData?.map(item => ({
    value: item.id,
    label: item.name,
    vat: item.vat,
  })), [vatGroupData]);

  const categoryOptions = useMemo(() => getOptions(categoryData), [categoryData]);
  const branchOptions = useMemo(() => getOptions(branchData), [branchData]);
  const projectOptions = useMemo(() => getOptions(projectData), [projectData]);
  const companyOptions = useMemo(() => getOptions(companyData), [companyData]);
  const allUserOptions = useMemo(() => getOptions(allUserData), [allUserData]);
  const wareHouseOptions = useMemo(() => getOptions(warehouseData), [warehouseData]);

  const productOptions = useMemo(() =>
    productData?.map(item => ({
      value: item.id,
      label: `${item.sku ? ` (${item.sku}) ` : ''} ${item.name} ${item.barcode ? ` [${item.barcode}]` : ''}`,
    })) || [], [productData]);
  const loadProductOptions = (inputValue, callback) => {
    const filteredOptions = inputValue ? productOptions.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())) : [];
    callback(filteredOptions);
  };


  const accountOptions = useMemo(() =>
    auxAccountData?.map(item => ({
      value: item.id,
      label: (item?.parent?.accountId ? item?.parent?.accountId + '.' : '') + item.accountId + ' ' + item.english + ' [' + item.phone + ']',
    })) || [], [auxAccountData]);
  const loadAccountOptions = (inputValue, callback) => {
    const filteredOptions = inputValue ? accountOptions.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())) : [];
    callback(filteredOptions);
  };


  const customerAccountOptions = useMemo(() =>
    customerAccountData?.map(item => ({
      value: item.id,
      label: (item?.parent?.accountId ? item?.parent?.accountId + '.' : '') + item.accountId + ' ' + item.english + ' [' + item.phone + ']',
    })) || [], [customerAccountData]);
  const loadCustomerAccountOptions = (inputValue, callback) => {
    const filteredOptions = inputValue ? customerAccountOptions.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())) : [];
    callback(filteredOptions);
  };


  const dataContextValue = useMemo(() => ({
    expireyDate,
    api,

    roleData,
    setAccess,
    session,
    sessionId,

    //currency
    mainCurrency,
    secondaryCurrency,
    rateMainToSecondaryCurrency,
    rateToMainCurrency,
    rateFromToCurrency,
    defaultCurrency,

    // selected data
    selectedCompany,
    selectedYear,
    companyId,
    yearId,

    companyOptions,

    devData,
    yearData,
    roleData,
    deliveryData,
    devSetting,
    refData,

    //tools
    posDetailsData,

    //account
    loadAccountOptions,
    accountOptions,

    loadCustomerAccountOptions,
    customerAccountOptions,

    employeeAccountData,
    expenseSupplierAccountData,

    accountData,
    parentAccountData,
    chartData,
    auxAccountData,
    accountGroupData,
    customerAccountData,
    supplierAccountData,
    expenseAccountData,
    treeData,

    // settings
    settingData,
    currencyData,
    currencyOptions,
    branchData,
    branchOptions,
    vatGroupData,
    companyData,
    userData,
    allUserData,
    allUserOptions,

    // inventory
    productData,
    productOptions,
    loadProductOptions,
    salableData,
    categoryData,
    categoryOptions,
    seasonData,
    projectData,
    warehouseData,
    projectOptions,
    wareHouseOptions,
    brandData,
    vatGroupOptions,


    //get Invoice by AccountId
    getInvoiceData,
    invoiceData,
    purchaseInvoiceData,

    //api
    localApi,
    serverApi,
    
  }));

  return (
    <div>

      <DataContext.Provider
        value={dataContextValue}>
        {isLoading &&
          <Spin size="large" tip="Loading your Data" fullscreen />
        }
        {!isLoading &&
          <PosCartData settingData={settingData} userData={userData} />
        }
      </DataContext.Provider>
    </div>

  );
}


export default GetData;