import React from 'react';
import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { numberFormatter, totalInFormatter, totalOutFormatter } from '../formatters/numberFormatter';
import { useReactToPrint } from 'react-to-print';


const PrintSessionModal = React.forwardRef(({ session, jvs, isUsingMainCurrency, defaultCurrency, summary }, ref) => {
  const columns = [
    { title: 'Type', dataIndex: 'type' },
    {
      title: 'In',
      dataIndex: isUsingMainCurrency ? 'totalDebit' : 'secondaryTotalDebit',
      render: totalInFormatter,
    },
    {
      title: 'Out',
      dataIndex: isUsingMainCurrency ? 'totalCredit' : 'secondaryTotalCredit',
      render: totalOutFormatter,
    },
  ];

  const openingBalance = isUsingMainCurrency ? session?.openingBalance : session?.secondaryOpeningBalance;
  const balance = (isUsingMainCurrency ? session?.totalIn - session?.totalOut : session?.secondaryTotalIn - session?.secondaryTotalOut);
  const totalBalance = (isUsingMainCurrency ? summary?.totalIn - summary?.totalOut : summary?.secondaryTotalIn - summary?.secondaryTotalOut); 

  return (
    <div ref={ref} style={{ fontFamily: 'Arial, sans-serif', padding: '20px', border: '1px solid #ddd', borderRadius: '10px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#f9f9f9' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '30px', color: '#003366', fontSize: '24px' }}>
        Session Details
      </h1>

      <Row className="mb-3" style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
        <Col md={4} style={{ fontWeight: 'bold', color: '#555' }}>Opened At:</Col>
        <Col md={8} style={{ color: '#333' }}>{dateTableFormatter(session?.openedAt)}</Col>
      </Row>

      <Row className="mb-3" style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
        <Col md={4} style={{ fontWeight: 'bold', color: '#555' }}>Closed At:</Col>
        <Col md={8} style={{ color: '#333' }}>{dateTableFormatter(session?.closedAt)}</Col>
      </Row>

      <Row className="mb-4" style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
        <Col md={4} style={{ fontWeight: 'bold', color: '#555' }}>Opening Balance:</Col>
        <Col md={8} style={{ color: '#333' }}>{numberFormatter(openingBalance)}{defaultCurrency?.symbol}</Col>
      </Row>

      <h2 style={{ margin: '20px 0', color: '#003366', fontSize: '20px', borderBottom: '2px solid #003366', paddingBottom: '5px' }}>
        Journal Vouchers
      </h2>
      <Table
        columns={columns}
        dataSource={jvs}
        pagination={false}
        bordered
        style={{ marginBottom: '20px' }}
      />

      <Row className="mb-3" style={{ alignItems: 'center' }}>
        <Col md={4} style={{ fontWeight: 'bold', color: '#555', display: 'flex', alignItems: 'center' }}>
          Balance:
        </Col>
        <Col md={8} style={{ color: '#333' }}>
          {numberFormatter(totalBalance)}{defaultCurrency?.symbol}
        </Col>
      </Row>
    </div>
  );
});

export default PrintSessionModal;