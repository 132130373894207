
import { ClockCircleOutlined, CheckCircleOutlined, TruckOutlined, LikeOutlined, RetweetOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Tag, Button } from 'antd';
import { numberFormatter } from './numberFormatter';
import { FaCheck } from "react-icons/fa";
import e from 'cors';



const paymentTypeFormatter = (value, record) => {

  if (value == 'cash') {
    return <Tag color="#87d068" style={{ width: '70px', textAlign: 'center' }}>Cash</Tag>
  }
  else if (value == 'onAccount') {
    return <Tag color="orange" style={{ width: '70px', textAlign: 'center' }}>onAccount</Tag>
  }

}


const statusFormatter = (value, record) => {

  if (value == 'UnPaid') {
    return <Tag color="error" style={{ width: '70px', textAlign: 'center' }}>{value}</Tag>
  }
  else if (value == 'SemiPaid') {
    return <Tag color="orange" style={{ width: '70px', textAlign: 'center' }}>{value}</Tag>
  }
  else if (value == 'Paid') {
    return <Tag color="success" style={{ width: '70px', textAlign: 'center' }}>{value}</Tag>
  }
  else if (value == 'OverPaid') {
    return <Tag color="processing" style={{ width: '70px', textAlign: 'center' }}>{value}</Tag>
  }
  else if (value == 'Voided') {
    return <Tag color="default" style={{ width: '70px', textAlign: 'center' }}>{value}</Tag>
  }
  else if (value == 'Active') {
    return <Tag color="success" style={{ width: '70px', textAlign: 'center' }}>{value}</Tag>
  }

}

const statusTextFormatter = (value) => {
  if (value === "UnPaid") {
    return "UnPaid";
  } else if (value === "SemiPaid") {
    return "SemiPaid";
  } else if (value === "Paid") {
    return "Paid";
  } else if (value === "OverPaid") {
    return "OverPaid";
  } else if (value === "Voided") {
    return "Voided";
  } else if (value === "Active") {
    return "Active";
  } else {
    return "Unknown"; // Handle unexpected values
  }
};


const isActiveFormatter = (value, record) => {

  if (value) {
    return <Tag color="success" style={{ width: '70px', textAlign: 'center' }}>Active</Tag>
  }
  else {
    return <Tag color="default" style={{ width: '70px', textAlign: 'center' }}>InActive</Tag>
  }

}

const isActiveTextFormatter = (value) => {
  return value ? "Active" : "Inactive";
};


const isMultiplyFormatter = (value, record) => {

  if (value) {
    return <span>Multiply</span>
  }
  else {
    return <span>Divide</span>
  }

}

const isMultiplyTextFormatter = (value) => {
  return value ? "Multiply" : "Divide";
};



const stockControlFormatter = (value, record) => {

  if (value) {
    return <Tag color="success" style={{ width: '70px', textAlign: 'center' }}>Stock In</Tag>
  }
  else {
    return <Tag color="orange" style={{ width: '70px', textAlign: 'center' }}>Stock Out</Tag>
  }

}

const isAuxFormatter = (value, record) => {

  if (value) {
    return <FaCheck color={'#23576a'} />
  } 

}

const natureFormatter = (value, record) => {
  if(!record.isActive){
    return <Tag style={{ width: '70px', textAlign: 'center' }}>Inactive</Tag>
  }else{
  if (value == 1) {
    return <Tag color="success" style={{ width: '80px', textAlign: 'center' }}>Debit</Tag>
  } else if (value == 0) {
    return <Tag color="orange" style={{ width: '80px', textAlign: 'center' }}>Credit</Tag>
  }
  else {
    return <Tag color="blue" style={{ width: '80px', textAlign: 'center' }}>Credit/Debit</Tag>
  }

}

}

const natureTextFormatter = (value, record) => {
  if (!record.isActive) {
    return "Inactive";
  } else if (value == 1) {
    return "Debit";
  } else if (value == 0) {
    return "Credit";
  } else {
    return "Credit/Debit";
  }
};


const quantityOutFormatter = (value, record) => {
  if (!record.transaction?.isDebit) {
    return numberFormatter(value*record.unitQuantity)
  }

}

const quantityInFormatter = (value, record) => {

  if (record.transaction?.isDebit) {
    return numberFormatter(value*record.unitQuantity);
  }

}

const typeFormatter = (type, record) => {

  if (record.isBank) {
    type = type = `Bank ${type}`;
  }
  if (record.isCredit) {
    return <Tag color="#f0b103"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{type}</Tag>
  }
  else {
    if (record.isOut) {
      return <Tag color="#ff4364"
        style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{type}</Tag>
    } else {
      return <Tag color="#2eb85c"
        style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{type}</Tag>
    }
  }

}


const stockActivitytypeFormatter = (transaction, record) => {

  if (!transaction.isOut) {
    return <Tag color="#ff4364"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  } else {
    return <Tag color="#2eb85c"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  }

}

const productSalesFormatter = (transaction, record) => {

  if (transaction.isOut) {
    return <Tag color="#ff4364"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  } else {
    return <Tag color="#2eb85c"
      style={{ cursor: 'pointer', fontSize: '15px', width: '90%', textAlign: "center" }}>{transaction.type}</Tag>
  }

}



export {
  typeFormatter,
  paymentTypeFormatter,
  stockActivitytypeFormatter,
  productSalesFormatter,
  quantityOutFormatter,
  quantityInFormatter,
  isAuxFormatter,
  statusFormatter,
  stockControlFormatter,
  natureFormatter,
  isActiveFormatter,
  isMultiplyFormatter,
  natureTextFormatter,
  isActiveTextFormatter,
  statusTextFormatter,
  isMultiplyTextFormatter,
};