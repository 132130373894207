import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FcBusinessman, FcKey } from "react-icons/fc";
import GetData from '../init/getData';
import SelectCompany from './selectCompany';
import { Input, Radio, Space } from 'antd';

function LogIn(props) {
  const { localApi, devSetting } = props;
  const [access, setAccess] = useState(false);
  const [userName, setUserName] = useState('');
  const [pinCode, setPinCode] = useState(['', '', '', '', '', '', '', '', '']); 
  const [usePinCode, setUsePinCode] = useState(false); 
  const [userNames, setUserNames] = useState([]); 
  const [token, setToken] = useState('');
  const [expireyDate, setExpireyDate] = useState('');
  const [loadingEffect, setLoadingEffect] = useState(false);
  const [userData, setUserData] = useState(false);
  let api;

  const serverApi = axios.create({  
    baseURL: devSetting?.serverLink,
    headers: {
      'Content-Type': 'application/json'
    }
  });


  if (devSetting?.isServer || userName === 'dev') {
    api = localApi;
  } else {
    api = serverApi;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingEffect(true);

    if (!devSetting.isWebApp && userName !== "dev") {
      const getLisence = await checkLisence();
      if (!getLisence) {
        toast.error('lisence expired')
        return;
      }
    }

    const checkLogIn = await logIn();

    if (checkLogIn) {
      setAccess(true);
    }

    setLoadingEffect(false);

  };

  const logIn = async () => {
    const form = document.getElementById("check_login_form");
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);


    let res = await api.post("/user/login", JSON.stringify(data));
    if (!res.data.status) {
      toast.error(res.data.message);
      return false;
    } else {
      toast.success('Login Successful');
      api.defaults.headers['Authorization'] = `Bearer ${res.data.token}`;
      serverApi.defaults.headers['Authorization'] = `Bearer ${res.data.token}`;
      localApi.defaults.headers['Authorization'] = `Bearer ${res.data.token}`;
      setToken(res.data.token);
      setUserData(res.data.userData);
      return true;
    }
  };

  const checkLisence = async () => {
    let res = await api.post("/user/getLicense");
    if (!res.data.status) {
      toast.error(res.data.message);
      return false;
    } else {
      setExpireyDate(res.data.data.date);
      return true;
    }
  };



  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  if (access && token) {
    return (
      <SelectCompany
        api={api}
        setAccess={setAccess}
        devSetting={devSetting}
        expireyDate={expireyDate}
        userData={userData}
        localApi={localApi}
        serverApi={serverApi}
      />
    );
  }


  return (
    <div style={{ height: '100vh', backgroundColor: '#23576a' }}>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh', backgroundColor: '#23576a' }}>
        <div className="row justify-content-center form-bg-image" style={{ width: 'auto' }}>
          <div className="col-12 d-flex align-items-center justify-content-center" >

            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500" style={{ backgroundColor: '#23576a' }}>
              <div className="text-center text-md-center mb-4 mt-md-0" >
                <h1 className="mb-0 h3">Sign in to our platform</h1>
              </div>


              <form method="POST" id="check_login_form" onSubmit={handleSubmit}>

                <div className="form-group mb-4">
                  <label>User Name</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className='bx'><FcBusinessman /></i>
                    </span>
                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="example" id="userName" name="userName" required />
                  </div>
                </div>

                <div className="form-group mb-4">
                  <label>Your Password</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon2">
                      <i className='bx'><FcKey /></i>
                    </span>
                    <input type="password" placeholder="Password" className="form-control" id="password" name="password" required />
                  </div>
                </div>


                <div className="d-grid" style={{ textAlign: 'center' }}>
                  <button style={{ backgroundColor: '#23576a', fontWeight: 'bold' }} className="btn btn-primary" type="submit">Sign in
                    {loadingEffect && <span className="spinner-border spinner-border-sm" style={{ marginLeft: '10px' }} role="status" aria-hidden="true"></span>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        <p style={{ color: 'white', fontWeight: 'bold' }}>&nbsp; &nbsp;  Copyright @  | Riser Team | By Dr.Samer Srour</p>
        <p style={{ color: 'white', fontWeight: 'bold' }}>&nbsp; &nbsp;  Whatsapp: +961 78937719 &nbsp; &nbsp;   Email:&nbsp;risersoftware@gmail.com</p>
      </div>
    </div>
  );
}

export default LogIn;